import { endConversationType } from '../common/types';
import { RxExit } from 'react-icons/rx';
import { useAuthActionCreators } from '../../../features/auth/store/authSlice';
import { useNavigate } from 'react-router';


export const EndAndLogOutComponent = ({handleEnd, endRecording}: endConversationType) => {
  const authActions = useAuthActionCreators();
  const navigate = useNavigate();

  const endAndLogOut = async () => {
    handleEnd('Press end, log out');
    endRecording();
    await authActions.logout();
    navigate('/login');
  };

  return(
    <button className={'holobox_button holobox_logout_button'} onClick={() => endAndLogOut()}>
      <RxExit className={'live_call_icon'} />
      <p>End &
        log out</p>
    </button>
  );
};