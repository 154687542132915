export const aiAvatarNickName = 'Ann';

export const heygenApiKey = 'OTFhMDg0YTlmYzc1NDc2YjkxNDczMmQzOWEwOTliYjItMTczNjM1NTc4NQ==';

export const apiHeygenConfig = {
  apiKey: heygenApiKey ?? '',
  serverUrl: 'https://api.heygen.com',
};

export const aiAvatarName = 'Ann_Therapist_public';

export const aiAvatarVoiceId = 'f5d52985019847c5b0501a445c66dba8';

export const oneSecond = 1000;

export const oneMinute = oneSecond * 60;