import { Form } from 'antd';
import styles from '../../styles/forms.module.scss';
import { Button, Input } from '../../../ui';
import { useTranslate } from '@tolgee/react';
import { LoginFormValues } from '../../interfaces/authConfig';
import { ValidateFormStatus } from '../../../../common/interfaces/validateFormStatus';
import type { ReactNode } from 'react';

interface LoginProps {
  onFinish: (values: LoginFormValues) => void;
  onChange: (changedValues: any, values: LoginFormValues) => void;
  validateStatus?: ValidateFormStatus;
  help?: ReactNode;
  disabled?: boolean;
  loading?: boolean;
}

export const AuthLoginPatient = ({ onFinish, onChange, validateStatus, help, disabled, loading }: LoginProps) => {
  const { t } = useTranslate();
  
  return(
    <Form
      data-testid="login-form"
      style={{ width: '100%' }}
      name="login"
      onFinish={onFinish}
      size="large"
      onValuesChange={onChange}
      disabled={disabled}
    >
      <Form.Item
        name="uniqueCode"
        className={styles.formItem}
        rules={[
          {
            required: true,
            message: t('features.AuthLogin.patientId.errEmpty')
          }
        ]}
        validateStatus={validateStatus}
        validateFirst
      >
        <Input placeholder={t('features.AuthLogin.patientInpt.placeholder')} />
      </Form.Item>
      <Form.Item>
        <Button type="primary" htmlType="submit" block className={styles.button} loading={loading}>
          {t('features.AuthLogin.loginBtn.text')}
        </Button>
      </Form.Item>
    </Form>
  );
};