import { createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../../../common/interfaces/store';
import { User } from '../../../common/interfaces/user';
import { ValidationError } from '../../../common/services/http/errors.helper';
import { setTokenToStorage } from '../../../common/utils/storage';
import { isInstanceOf } from '../../../common/utils/typeUtils';
import { AuthConfig, ChangePasswordFormValues, LoginFormValues } from '../interfaces/authConfig';
import { AuthDaoService } from '../services/AuthDaoService';

const login = createAsyncThunk<Partial<AuthConfig>, LoginFormValues>('auth/login', async (values, thunkApi) => {
  try {
    const data = await AuthDaoService.login(values);
    if (data.changePassword) {
      return {
        ...data,
        oldPassword: values.password,
        email: values.email
      };
    }

    setTokenToStorage(data.token, data.tokenExpires);
    return data;
  } catch (error) {
    if (isInstanceOf(error, ValidationError)) {
      throw error;
    }
  }
  return {};
});

const changePassword = createAsyncThunk<Partial<AuthConfig>, ChangePasswordFormValues, { state: RootState }>(
  'auth/change-password',
  async (values, thunkApi) => {
    const authConfig = thunkApi.getState().auth;
    try {
      await AuthDaoService.changePassword({
        password: values.password,
        email: authConfig.email!,
        oldPassword: authConfig.oldPassword!
      });
      return {
        oldPassword: null,
        email: null
      };
    } catch (error) {
      if (isInstanceOf(error, ValidationError)) {
        throw error;
      }
    }
    return {};
  }
);

export const getUser = createAsyncThunk<User>('auth/get-user', async () => {
  const user = await AuthDaoService.getUser();
  return user;
});

export const logout = createAsyncThunk('auth/logout', async () => {
  await AuthDaoService.logout();
});

export const loginPatient = createAsyncThunk<
  Partial<AuthConfig>,
  LoginFormValues
>(
  'auth/login',
  async ({ uniqueCode }: LoginFormValues, thunkApi) => {
    try {
      const data = await AuthDaoService.loginPatient({uniqueCode:  uniqueCode ? uniqueCode : '0', firebaseToken: null });
      if (data.changePassword) {
        return {
          ...data,
          email: data.user.email,
        };
      }

      setTokenToStorage(data.token, data.tokenExpires);
      return data;
    } catch (error) {
      if (isInstanceOf(error, ValidationError)) {
        throw error;
      }
    }
    return {};
  }
);

export const authThunks = {
  login,
  logout,
  changePassword,
  getUser,
  loginPatient
};
