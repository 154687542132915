import { Dispatch, RefObject, SetStateAction, useEffect, useState } from 'react';
import { MessagesType, SessionInfo } from '../common/types';
import { Socket } from 'socket.io-client';
import { SocketDaoServices } from '../services/SocketDaoServices';
import { useAuthConfigSelector } from '../../../features/auth/store/authSelectors';
import { AIAvatarDaoServices } from '../services/AIAvatarDaoServices';


type useSocketsType = {
  setIsConnected: Dispatch<SetStateAction<boolean>>,
  sessionInfoRef: RefObject<SessionInfo | null>
  setMessages: Dispatch<SetStateAction<MessagesType[]>>,
  setIsSendingMessage: Dispatch<SetStateAction<boolean>>,
  turnOffIdleVideo: () => void,
  setErrorMessage: Dispatch<SetStateAction<string>>,

}



const useSockets = ({setIsConnected, sessionInfoRef, setMessages, setIsSendingMessage, turnOffIdleVideo, setErrorMessage}: useSocketsType) => {
  const [socket, setSocket] = useState<Socket | null >(null);
  const [isFirstStart, setIsFirstStart] = useState(true);
  const { authorized, token } = useAuthConfigSelector();
  useEffect(() => {
    if(token){
      SocketDaoServices.initiate(token);
    }
    const newSocket = SocketDaoServices.connect();
    setSocket(newSocket);

    newSocket.on('connect', () => {
      setIsConnected(true);
    });

    newSocket.on('response', async (data: { message: string }) => {
      setTimeout(async ()  =>{
        if(sessionInfoRef.current){
          await AIAvatarDaoServices.sendText(data.message, sessionInfoRef.current, setErrorMessage);
          if(isFirstStart){
            setIsFirstStart(false);
          }
        }
        setMessages((prev) => [...prev, { user: '', bot: data.message }]);
        setIsSendingMessage(false);
        turnOffIdleVideo();
      },isFirstStart ? 2000 : 0);
    });

    newSocket.on('disconnect', () => {
      setIsConnected(false);
      console.log('Socket disconnected');
    });

    newSocket.on('connect_error', (err) => {
      console.error('Socket connection error:', err);
      setErrorMessage('Socket connection error');
    });

    return () => {
      newSocket.off('response');
      newSocket.off('connect');
      newSocket.off('disconnect');
      newSocket.off('connect_error');
      newSocket.disconnect();
    };
  }, []);
  return{
    socket
  };
};

export default useSockets;