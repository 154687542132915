import {
  ClinicServiceDaoService,
  Provider,
  Service
} from '../../../features/clinicService/services/ClinicServiceDaoService';
import { useEffect, useState } from 'react';
import { ServicesComponent } from '../components/ServicesComponent';
import { ListOfUsersComponent } from '../components/ListOfUsersComponent';

export const ChooseServicesForLiveCall = () => {

  const [services, setServices] = useState<Service[]>([]);
  const [selectedService, setSelectedService] = useState<Service | null>(null);
  const [users, setUsers] = useState<Provider[]>([]);

  useEffect(() => {
    const getItem = async () => {
      const response =  await ClinicServiceDaoService.getServices();
      setServices(response);
    };
    getItem();
  }, []);

  
  return (<div style={{ display: 'flex', flexDirection: 'column', gap: 20 }}>
    {!selectedService ?
      <ServicesComponent services={services} setSelectedService={setSelectedService} setUsers={setUsers} />
      :
      <ListOfUsersComponent setSelectedService={setSelectedService} users={users} selectedService={selectedService}/>
    }
  </div>);
};