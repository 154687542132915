import { T, useTranslate } from '@tolgee/react';
import { useEffect, useState } from 'react';
import { StateStatus } from '../../common/enums/store';
import { ValidateFormStatus } from '../../common/interfaces/validateFormStatus';
import { AuthLogin } from '../../features/auth/components/AuthLogin/AuthLogin';
import { LoginFormValues } from '../../features/auth/interfaces/authConfig';
import { useAuthConfigSelector } from '../../features/auth/store/authSelectors';
import { useAuthActionCreators } from '../../features/auth/store/authSlice';
import { AuthLoginPatient } from '../../features/auth/components/AuthLoginPatient/AuthLoginPatient';
import { useNavigate } from 'react-router-dom';

interface FormStatus {
  validateStatus?: ValidateFormStatus;
  help: JSX.Element | undefined;
}

const initialFormStatus: FormStatus = {
  validateStatus: undefined,
  help: undefined
};

const errorFormStatus: FormStatus = {
  validateStatus: ValidateFormStatus.ERROR,
  help: <T keyName='pages.LoginPage.errCredentials' />
};

const unauthorizedForStatus: FormStatus = {
  validateStatus: ValidateFormStatus.ERROR,
  help: <T keyName='pages.LoginPage.errUnauthorized' />
};

export const LoginPage = () => {
  const { t } = useTranslate();

  const [formStatus, setFormStatus] = useState<FormStatus>(initialFormStatus);
  const [isProviderView, setIsProviderView] = useState(true);
  const authActions = useAuthActionCreators();
  const { status, error } = useAuthConfigSelector();
  const navigate = useNavigate();
  useEffect(() => {
    status === StateStatus.ERROR &&
      (error.name === 'UnauthorizedError' ? setFormStatus(unauthorizedForStatus) : setFormStatus(errorFormStatus));
  }, [status]);

  const onFinish = (values: LoginFormValues) => {
    console.log(values);
    if(values.uniqueCode) {
      authActions.loginPatient(values);
    }
    else if (!formStatus.validateStatus) {
      authActions.login(values);
    }
  };

  const onChange = () => {
    if (formStatus.validateStatus != null) {
      setFormStatus(initialFormStatus);
    }
  };

  return (
    <div className="centered-content">
      <div className="form-container">
        <h4>{t('pages.LoginPage.title')}</h4>
        {isProviderView ?
          <AuthLogin
            onFinish={onFinish}
            onChange={onChange}
            validateStatus={formStatus.validateStatus}
            help={formStatus.help}
            disabled={status === StateStatus.LOADING}
            loading={status === StateStatus.LOADING}
          />
          : <AuthLoginPatient onFinish={onFinish} onChange={onChange}/>
        }
        <div className={'form-switch-container'}>
          {t(isProviderView ? 'feature.login.loginAsPatient' : 'feature.login.loginAsProvider',{
            link:  (text: string) => <p className={'form-switch'} onClick={() => setIsProviderView(prev => !prev)}>{text}</p>,
          } as any)}
        </div>
      </div>
    </div>
  );
};
