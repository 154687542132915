import { Dispatch, RefObject, SetStateAction, useEffect, useRef } from 'react';

type AIAvatarVideoTypes = {
  mediaElement: RefObject<HTMLVideoElement>;
  isIdleVideo: boolean;
  isAvatarMuted: boolean;
  isEndWelcomeVideo?: boolean;
  setIsEndWelcomeVideo?: Dispatch<SetStateAction<boolean>>;
  isIdleImg: boolean;
}

export const AIAvatarVideo = ({mediaElement, isIdleVideo, isAvatarMuted, isEndWelcomeVideo, setIsEndWelcomeVideo, isIdleImg}: AIAvatarVideoTypes) => {

  const welcomeVideoRef = useRef<HTMLVideoElement | null>(null);

  useEffect(() => {
    if(!isEndWelcomeVideo){
      welcomeVideoRef.current?.play();
    }

  },[isEndWelcomeVideo]);

  return (
    <div className="video_block">
      <img style={{display: `${isIdleImg ? 'block' : 'none'}`}} className={'idle_video_block'} src={`${process.env.PUBLIC_URL}/meta/jamaica/idle img.png`} alt={'idle img'}/>
      <video
        ref={welcomeVideoRef}
        className={'idle_video_block'}
        src={`${process.env.PUBLIC_URL}/meta/jamaica/Ann welcome video.mp4`}
        style={{ display: `${isEndWelcomeVideo ? 'none' : 'block'}` }}
        playsInline
        onEnded={() => {
          if (setIsEndWelcomeVideo)
            setIsEndWelcomeVideo(true);
        }}
      />
      <video
        ref={mediaElement}
        id="mediaElement"
        className={isIdleVideo ? 'idle_video_block' : 'video_block_content'}
        autoPlay
        muted={isIdleVideo || isAvatarMuted}
        src={isIdleVideo ? `${process.env.PUBLIC_URL}/meta/jamaica/Ann nodding 2.mp4` : mediaElement.current?.src}
        loop={isIdleVideo}
        playsInline
      />
    </div>
  );
};
