import {useState} from 'react';

const useMuteAvatar = () => {
  const [isAvatarMuted, setIsAvatarMuted] = useState(false);

  const muteAvatar =  () => {
    setIsAvatarMuted(prevState => !prevState);
  };

  return {isAvatarMuted,
    muteAvatar};


};

export default useMuteAvatar;