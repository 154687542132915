import { useRef, useState } from 'react';
import useMuteAvatar from './hooks/useMuteAvatar';
import { aiAvatarNickName } from './common/constants';
import { NameOfAvatarComponent } from './components/NameOfAvatarComponent';
import { AIAvatarVideo } from './components/AIAvatarVideo';
import { AudioHologramComponent } from './components/AudioHologramComponent';
import { StartALiveCallButtonComponent } from './components/StartALiveCallButtonComponent';
import { EndAndLogOutComponent } from './components/EndAndLogOutComponent';
import { MessagesHolobox } from './features/MessagesHolobox';
import useChatMessages from './hooks/useChatMessages';
import './styles.css';
import useRecordingAudio from './hooks/useRecordingAudio';
import { TimerComponent } from './components/TimerComponent';

export const WebPageAIAssistant = () => {
  const mediaElement = useRef<HTMLVideoElement | null>(null);
  const {isAvatarMuted, muteAvatar} = useMuteAvatar();
  const {messages, handleEnd, isIdleVideo, handleSend, setOwnMessage, errorMessage, ownMessage, isSendingMessage, isFirstRecord, setIsFirstRecord, leftTime} = useChatMessages({mediaElement});
  const {isRecording, stopRecording, startRecording, endRecording} = useRecordingAudio({setOwnMessage, handleSend});
  const [isEndWelcomeVideo, setIsEndWelcomeVideo] = useState<boolean>(true);
  const [isIdleImg, setIsIdleImg] = useState<boolean>(true);
  return (<div>
    <div className={'ai_avatar_name'}>
      <NameOfAvatarComponent name={aiAvatarNickName}/>
    </div>
    <TimerComponent leftTime={leftTime}/>
    <AIAvatarVideo isIdleImg={isIdleImg} isEndWelcomeVideo={isEndWelcomeVideo} setIsEndWelcomeVideo={setIsEndWelcomeVideo} isAvatarMuted={isAvatarMuted} mediaElement={mediaElement} isIdleVideo={isIdleVideo}/>
    <div className={'ai_hologram_container'}>
      <AudioHologramComponent ownMessage={ownMessage} setIsIdleImg={setIsIdleImg} endRecording={endRecording} handleSend={handleSend} isRecording={isRecording} stopRecording={stopRecording} startRecording={startRecording}  setIsFirstRecord={setIsFirstRecord} isFirstRecord={isFirstRecord} isEndWelcomeVideo={isEndWelcomeVideo} setIsEndWelcomeVideo={setIsEndWelcomeVideo}  handleEnd={handleEnd} isAvatarMuted={isAvatarMuted} muteAvatar={muteAvatar} isIdleVideo={isIdleVideo} isSendingMessage={isSendingMessage}/>
      <StartALiveCallButtonComponent endRecording={endRecording} handleEnd={handleEnd}/>
      <EndAndLogOutComponent endRecording={endRecording} handleEnd={handleEnd}/>
    </div>
    <MessagesHolobox messages={messages} errorMessage={errorMessage} ownMessage={ownMessage} isSendingMessage={isSendingMessage}/>
  </div>);
};