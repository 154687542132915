
type NameOfAvatarComponentType = {
  name: string;
}

export const NameOfAvatarComponent = ({name}: NameOfAvatarComponentType) => {

  return (<div className={'avatar_name_block'}>
    <p className={'avatar_name_text'}>{name}</p>
  </div>);
};