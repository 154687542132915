
import io from 'socket.io-client';
import { baseUrl } from '../../../common/constants';


let Authorization = '';

const config = () => {
  if (!Authorization) {
    throw new Error('SocketService is not initiated with token');
  }
  return {
    extraHeaders: {
      Authorization,
    },
    autoconnect: true,
    reconnection: true,
    reconnectionAttempts: Infinity,
    reconnectionDelay: 1000,
    reconnectionDelayMax: 5000,
  };
};

const initiate = (token: string) => {
  Authorization = 'Bearer ' + token;
};

const connect = () => {
  const socket = io(`${baseUrl}/ai-chat`, config());
  return socket;
};

export const SocketDaoServices ={
  initiate,
  connect

};