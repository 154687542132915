import { MessagesBlockTypes } from '../common/types';
import { MessageComponent } from '../components/MessageComponent';
import { useEffect, useRef } from 'react';


type MessagesHolobox = MessagesBlockTypes & {
  ownMessage: string;
  isSendingMessage: boolean;
}
export const MessagesHolobox = ({messages, errorMessage, ownMessage, isSendingMessage}: MessagesHolobox) => {
  const lastMessageRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (lastMessageRef.current) {
      lastMessageRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' });
    }
  }, [messages, ownMessage, isSendingMessage]);

  return (
    <div className={'messages_holobox_container'}>
      <div className="messages_holobox">
        {errorMessage ?
          <MessageComponent message={errorMessage} className={'error_message'} />
          :
          <>
            {
              messages.map((message, index) => (
                <MessageComponent key={index} message={message.user || message.bot}
                  className={message.user ? 'message_right' : 'message_left'} />
              ))
            }
            {ownMessage && <MessageComponent message={ownMessage + '...'} className={'message_right'} />}
            {
              isSendingMessage && <MessageComponent message={'...'} className={'message_left'} />
            }
            <div ref={lastMessageRef}></div>
          </>}
      </div>
    </div>);
};