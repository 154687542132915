import { useTolgee, useTranslate } from '@tolgee/react';
import { useMemo } from 'react';
import { AccessConfigEnum, AccessService } from '../../features/access/AccessService';
import { AppointmentStatusEnum } from '../../features/appointment/enums/appointmentEnums';
import { getFetchUsers } from '../../features/appointment/utils/helpers';
import { ClinicServiceEnum } from '../../features/appointmentCreate/enums/clinicService';
import { InlineFilter } from '../../features/ui/FiltersPanel';
import { PickerViewType } from '../../features/ui/FiltersPanel/components/DateRangePicker/DateRangePicker';
import { AppointmentTypeEnum } from '../enums/appointmentTypeEnum';
import { UserRoleEnum } from '../enums/userRoleEnum';

export const useFilters = ({
  picker,
  onPickerChange
}: {
  picker: PickerViewType;
  onPickerChange: (picker: PickerViewType) => void;
}) => {
  const { t } = useTranslate();
  const tInstance = useTolgee(['language']);
  const lang = tInstance.getLanguage();

  const filterStatus = {
    filter: {
      filterKey: 'status'
    },
    type: InlineFilter.MultiSelect,
    label: t('common.appointmentStatuses.title'),
    dataSource: [
      {
        label: t('common.appointmentStatuses.confirmed.text'),
        key: AppointmentStatusEnum.CONFIRMED
      },
      {
        label: t('common.appointmentStatuses.requestedByTester.text'),
        key: AppointmentStatusEnum.REQUESTED_BY_TESTER
      },
      {
        label: t('common.appointmentStatuses.requestedByPatient.text'),
        key: AppointmentStatusEnum.REQUESTED_BY_PATIENT
      },
      {
        label: t('common.appointmentStatuses.requestedByProvider.text'),
        key: AppointmentStatusEnum.REQUESTED_BY_PROVIDER
      },
      {
        label: t('common.appointmentStatuses.active.text'),
        key: AppointmentStatusEnum.ACTIVE
      }
    ]
  };

  const filterSort = {
    filter: {
      filterKey: 'sortBy',
      hidden: true,
      sticky: true
    },
    label: t('common.sortBy.title'),
    type: InlineFilter.RadioSelect,
    dataSource: [
      {
        label: t('common.sortBy.latest.text'),
        key: 'latest'
      },
      {
        label: t('common.sortBy.soonest.text'),
        key: 'soonest'
      }
    ]
  };

  const filterType = {
    filter: {
      filterKey: 'type'
    },
    label: t('common.appointmentContactMethod.title'),
    type: InlineFilter.MultiSelect,
    dataSource: [
      {
        label: t('common.appointmentContactMethod.video.text'),
        key: AppointmentTypeEnum.video
      },
      {
        label: t('common.appointmentContactMethod.audio.text'),
        key: AppointmentTypeEnum.audio
      },
      {
        label: t('common.appointmentContactMethod.chat.text'),
        key: AppointmentTypeEnum.text
      },
      {
        label: t('common.appointmentContactMethod.hologram.text'),
        key: AppointmentTypeEnum.hologram
      }
    ]
  };

  const filterPatientName = {
    filter: {
      filterKey: 'patientFullName'
    },
    type: InlineFilter.SearchSelect,
    label: t('common.patient.label'),
    dataSource: [],
    hidden: !AccessService.isMatchAccessConfig(AccessConfigEnum.appoitnemtsPatientsFilter, UserRoleEnum.Admin),
    fetchData: getFetchUsers([UserRoleEnum.User])
  };

  const filterProviderName = {
    filter: {
      filterKey: 'providerFullName'
    },
    type: InlineFilter.SearchSelect,
    label: t('common.providerName.label'),
    dataSource: [],
    fetchData: getFetchUsers([UserRoleEnum.Provider, UserRoleEnum.Admin, UserRoleEnum.PeerNavigator])
  };

  const filterProviderType = {
    filter: {
      filterKey: 'clinicService'
    },
    label: t('common.providerType.label'),
    type: InlineFilter.MultiSelect,
    dataSource: [
      {
        key: ClinicServiceEnum.Clinician,
        label: t('common.clinicServiceTypes.Clinician.name')
      },
      {
        key: ClinicServiceEnum.CaseManager,
        label: t('common.clinicServiceTypes.CaseManager.name')
      },
      {
        key: ClinicServiceEnum.ContactInvestigator,
        label: t('common.clinicServiceTypes.ContactInvestigator.name')
      },
      {
        key: ClinicServiceEnum.Psychologist,
        label: t('common.clinicServiceTypes.Psychologist.name')
      },
      {
        key: ClinicServiceEnum.Pharmacist,
        label: t('common.clinicServiceTypes.Pharmacist.name')
      },
      {
        key: ClinicServiceEnum.PeerNavigator,
        label: t('common.clinicServiceTypes.PeerNavigator.name')
      }
    ]
  };
  const filterDateRange = useMemo(
    () => ({
      filter: {
        filterKey: 'date-range',
        hidden: true
      },
      type: InlineFilter.DateRangePicker,
      label: t('common.dataRange.title'),
      // DateRangePicker is like two different filters
      dataSource: [
        {
          label: t('common.dataRange.from.text'),
          key: 'startDatetime'
        },
        {
          label: t('common.dataRange.to.text'),
          key: 'endDatetime'
        }
      ],
      extra: {
        picker,
        onPickerChange
      }
    }),
    [picker, lang, onPickerChange]
  );

  return {
    filterDateRange,
    filterPatientName,
    filterType,
    filterSort,
    filterStatus,
    filterProviderName,
    filterProviderType
  };
};
