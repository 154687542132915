import {useState} from 'react';

const useIdleVideo = () => {
  const [isIdleVideo, setIsIdleVideo] = useState<boolean>(true);

  const turnOnIdleVideo = () =>{
    setIsIdleVideo(true);
  };

  const turnOffIdleVideo = () =>{
    setIsIdleVideo(false);
  };
  return{
    isIdleVideo,
    turnOnIdleVideo,
    turnOffIdleVideo
  };
};

export default useIdleVideo;