import { AIAssistantLogInterface } from '../common/types';
import { TelehealthHttpService } from '../../../common/services/http/http.service';

const sendLogs = async (logs: AIAssistantLogInterface) => {
  const response = await TelehealthHttpService.url('/ai-assistant/log/send').post(logs).then(res => console.log(res.data)).catch(err => console.log(err));
  return response;
};

export const LogDaoServices = {
  sendLogs
};