import {
  ClinicServiceDaoService,
  Provider,
  Service
} from '../../../features/clinicService/services/ClinicServiceDaoService';
import { Button } from 'antd';
import { AppointmentsDaoService } from '../../webPageAIAssistant/services/AppointmentsDaoService';
import { AppointmentTypeEnum } from '../../../common/enums/appointmentTypeEnum';
import { Dispatch, SetStateAction, useState } from 'react';
import { useNavigate } from 'react-router';
import { BackButton } from '../../../features/ui';
import { useTranslate } from '@tolgee/react';
import { ProfileAvatar } from '../../../features/profile/components/ProfileAvatar/ProfileAvatar';
import style from '../../../features/profile/components/ProfileProvider/profile-provider.module.scss';

type ListOfUsersComponentType = {
  users: Provider[];
  selectedService: Service;
  setSelectedService: Dispatch<SetStateAction<Service | null>>;
}

export const ListOfUsersComponent = ({users, selectedService, setSelectedService}: ListOfUsersComponentType) => {
  const [meetingId, setMeetingId] = useState<string | null>(null);
  const navigate = useNavigate();
  const {t} = useTranslate();
  const checkConnection = async () => {
    if(meetingId) {
      const data =  await AppointmentsDaoService.getAppointmentById(meetingId);
      navigate(`/join/appointments/${data.meetingId}`, {
        state: {
          appointment: data
        }
      });
    }
  };

  const startCall = async (providerId: number) => {
    const dates = await ClinicServiceDaoService.getProviderSlots(providerId);
    if(selectedService) {
      const payload = {
        slot: {
          time: new Date().toISOString(),
          types: [AppointmentTypeEnum.hologram],

        },
        type: AppointmentTypeEnum.hologram,
        providerId: providerId,
        clinicServiceId: selectedService.id,
        comment: 'live call'
      };

      const meetingInfo =  await AppointmentsDaoService.postBookAppointment(payload);
      setMeetingId(meetingInfo.id.toString());
    }
  };

  return(
    <>
      <div style={{
        display: 'flex',
        textAlign: 'center',
        justifyContent: 'space-between',
        alignContent: 'center',
        gap: 20,
        borderBottom: '1px solid rgba(0, 0, 0, 0.25)'
      }}>
        <BackButton label={t('common.backBtn.label')} onClick={() => setSelectedService(null)} />
        <p>Provider</p>
        <div style={{ width: 80 }}></div>
      </div>
      {
        users?.map((user: Provider) => (
          <div key={user.id} style={{ display: 'flex', flexDirection: 'column', border: '1px solid black' }}>
            <div style={{
              display: 'flex',
              flexDirection: 'column',
              border: '1px solid rgba(221, 221, 221, 1)',
              padding: 10
            }}>
              <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 20 }}>
                <div>
                  <p>{user.prefix ? user.prefix : '' + ' ' + user.firstName + ' ' + user.lastName}</p>
                  <p>{user.bio}</p>
                </div>
                <ProfileAvatar uniqueCode={user.uniqueCode} className={style.avatar} />
              </div>
              

              {meetingId ?
                <Button type={'primary'} onClick={() => checkConnection()}>Check connection</Button>
                :
                <Button type={'primary'} onClick={() => startCall(user.id)}>Create meeting</Button>}
            </div>
          </div>
        ))
      }
    </>
  );
};