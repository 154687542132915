import { RefObject, useEffect, useRef, useState } from 'react';
import { AIAssistantLogInterface, MessagesType, SessionInfo } from '../common/types';
import useIdleVideo from './useIdleVideo';
import useSockets from './useSockets';
import { AIAvatarDaoServices } from '../services/AIAvatarDaoServices';
import { useAuthConfigSelector } from '../../../features/auth/store/authSelectors';
import { LogDaoServices } from '../services/LogDaoServices';


type useChatMessagesType = {
  mediaElement: RefObject<HTMLVideoElement | null>,
}

const useChatMessages = ({mediaElement}: useChatMessagesType) => {
  const [peerConnection, setPeerConnection] = useState<RTCPeerConnection | null>(null);
  const [messages, setMessages] = useState<MessagesType[]>([]);
  const [ownMessage, setOwnMessage] = useState<string>('');
  const [sessionInfo, setSessionInfo] = useState<SessionInfo | null>(null);
  const [startDate, setStartDate] = useState<Date | null>(null);
  const sessionInfoRef = useRef<SessionInfo | null>(null);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const {isIdleVideo, turnOnIdleVideo, turnOffIdleVideo} = useIdleVideo();
  const [isSendingMessage, setIsSendingMessage] = useState(false);
  const [isConnected, setIsConnected] = useState<boolean>(false);
  const {socket} = useSockets({setIsConnected, sessionInfoRef, setMessages, setIsSendingMessage, turnOffIdleVideo, setErrorMessage});
  const { user } = useAuthConfigSelector();
  const [isFirstRecord, setIsFirstRecord] = useState<boolean>(true);
  const [leftTime, setLeftTime] = useState(570);

  const handleStart = async () => {
    try {
      await AIAvatarDaoServices.createNewSession(setSessionInfo, setPeerConnection, mediaElement, setErrorMessage);
      setStartDate(new Date());
    } catch (error) {
      setErrorMessage('Error starting session');
      console.error('Error starting session:', error);
    }
  };

  const handleSend = async () => {
    if(ownMessage) {
      setIsSendingMessage(true);
      if (isConnected && socket) {
        if (!sessionInfo) {
          await handleStart();
        }
        setMessages((prev) => [...prev, {user: ownMessage, bot: ''}]);
        socket.emit('message', {message: ownMessage});
        setOwnMessage('');
      } else {
        setErrorMessage('Socket not connected yet');
        console.log('Socket not connected yet');
      }
    }
  };

  const handleEnd = async (typeOfAction: string) => {

    const logs: AIAssistantLogInterface = {
      userId: user?.id || 0,
      sessionId: sessionInfo?.session_id || '',
      startDate: startDate || new Date(),
      endDate: new Date(),
      typeOfAction: typeOfAction

    };
    await LogDaoServices.sendLogs(logs);
    await AIAvatarDaoServices.closeSession(sessionInfo, peerConnection, setPeerConnection, setSessionInfo, mediaElement);
    setMessages([]);
    turnOnIdleVideo();
    setOwnMessage('');
    setErrorMessage('');
    setIsFirstRecord(true);
  };
  useEffect(() => {
    sessionInfoRef.current = sessionInfo;
  }, [sessionInfo]);

  useEffect(() => {
    if(errorMessage || !isIdleVideo){
      turnOffIdleVideo();
    }
  }, [errorMessage]);

  useEffect(() => {
    if(!isFirstRecord) {
      if (leftTime <= 0) {
        handleEnd('Time end');
      }

      const interval = setInterval(() => {
        setLeftTime(prevTime => prevTime - 1);
      }, 1000);

      return () => clearInterval(interval);
    }
    else{
      setLeftTime(570);
    }
  }, [leftTime, isFirstRecord]);

  return {
    messages,
    handleSend,
    handleEnd,
    errorMessage,
    ownMessage,
    isSendingMessage,
    isIdleVideo,
    setOwnMessage,
    isConnected,
    isFirstRecord,
    setIsFirstRecord,
    leftTime
  };
};

export default useChatMessages;