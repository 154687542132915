import axios from 'axios';
import {Dispatch, RefObject, SetStateAction} from 'react';
import { SessionInfo } from '../common/types';
import { aiAvatarName, aiAvatarVoiceId, apiHeygenConfig } from '../common/constants';

const createNewSession = async (setSessionInfo: Dispatch<SetStateAction<SessionInfo | null>>, setPeerConnection: Dispatch<SetStateAction<RTCPeerConnection | null>>, mediaElement: RefObject<HTMLVideoElement| null>, setErrorMessage: Dispatch<SetStateAction<string>>) => {
  const response = await fetch(`${apiHeygenConfig.serverUrl}/v1/streaming.new`, {
    method: 'POST',
    headers: {
      accept: 'application/json',
      'content-type': 'application/json',
      'x-api-key': apiHeygenConfig.apiKey,
    },
    body: JSON.stringify({
      quality: 'high',
      avatar_name: aiAvatarName,
      voice: {
        voice_id: aiAvatarVoiceId,
        rate: 1
      },
      video_encoding: 'VP8',
      disable_idle_timeout: true,
    }),
  });
  const data = await response.json();
  const session = data.data as SessionInfo;
  setSessionInfo(data.data as SessionInfo);
  if(session) {
    const {sdp: serverSdp, ice_servers2: iceServers} = session;
    const connection = new RTCPeerConnection({ iceServers });
    setPeerConnection(connection);
    if(connection){
      connection.ontrack = (event) => {
        if (event.track.kind === 'audio' || event.track.kind === 'video') {
          if (mediaElement.current && event.streams[0]) {
            mediaElement.current.srcObject = event.streams[0];
            const stream = event.streams[0];
            if (stream && !stream.active) {
              stream.getTracks().forEach(track => track.enabled = true);

            }
          } else {
            setErrorMessage('We are sorry, currently Digital Avatar could not reach AI to provide you feedback');
            console.error('Media stream is not available');
          }
        }
        else{
          setErrorMessage('We are sorry, currently Digital Avatar could not reach AI to provide you feedback');
        }
      };
    }
    await connection?.setRemoteDescription(new RTCSessionDescription(serverSdp));
    await startMeetingSession(connection, session);
  }
  else{
    setErrorMessage('We are sorry, currently Digital Avatar could not reach AI to provide you feedback');
  }

  console.log('Session created successfully');
};

const handleICE = async (session_id: string, candidate: RTCIceCandidateInit) => {
  try {
    const response = await fetch(`${apiHeygenConfig.serverUrl}/v1/streaming.ice`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-Api-Key': apiHeygenConfig.apiKey,
      },
      body: JSON.stringify({
        session_id,
        candidate,
      }),
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
  } catch (error) {
    console.error('ICE candidate error:', error);
  }
};

const startMeetingSession = async (peerConnection: RTCPeerConnection | null, sessionInfo: SessionInfo | null) => {
  const localDescription = await peerConnection?.createAnswer();
  await peerConnection?.setLocalDescription(localDescription);

  // Step 2: Handle ICE candidate gathering
  if(peerConnection)
    peerConnection.onicecandidate = ({ candidate }) => {
      if (candidate) {
        // Send candidates to server for connection establishment
        if(sessionInfo)
          handleICE(sessionInfo.session_id, candidate.toJSON());
      }
    };

  // Step 3: Monitor connection states
  if(peerConnection)
    peerConnection.oniceconnectionstatechange = () => {
      const state = peerConnection?.iceConnectionState;
      console.log(`ICE Connection State: ${state}`);
      // Connection states and their meanings:
      // - 'checking': Testing connection paths
      // - 'connected': Media flowing
      // - 'completed': All ICE candidates verified
      // - 'failed': No working connection found
      // - 'disconnected': Temporary connectivity issues
      // - 'closed': Connection terminated
    };

  // Step 4: Complete connection setup
  const startResponse = await fetch(`${apiHeygenConfig.serverUrl}/v1/streaming.start`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'X-Api-Key': apiHeygenConfig.apiKey,
    },
    body: JSON.stringify({
      session_id: sessionInfo && sessionInfo.session_id,
      sdp: localDescription,
    }),
  });
  console.log(startResponse);
};

const sendText = async (text: string, sessionInfo: SessionInfo | null, setErrorMessage: Dispatch<SetStateAction<string>>) => {
  if (!sessionInfo) {
    setErrorMessage('We are sorry, currently Digital Avatar could not reach AI to provide you feedback');
    console.log('No active session');
    return;
  }
  try {
    const response = await fetch(`${apiHeygenConfig.serverUrl}/v1/streaming.task`, {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        'X-Api-Key': apiHeygenConfig.apiKey,
      },
      body: JSON.stringify({
        session_id: sessionInfo.session_id,
        text,
        'task_type': 'repeat'
      }),
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    console.log(`Sent text: ${text}`);
  } catch (error) {
    setErrorMessage('We are sorry, currently Digital Avatar could not reach AI to provide you feedback');
    console.error('Send text error:', error);
  }
};

const closeSession = async (sessionInfo: SessionInfo | null, peerConnection: RTCPeerConnection | null, setPeerConnection: Dispatch<SetStateAction<RTCPeerConnection | null>>, setSessionInfo: Dispatch<SetStateAction<SessionInfo | null>>, mediaElement:  RefObject<HTMLVideoElement | null>) => {
  console.log('close session');
  if (!sessionInfo) {
    return;
  }

  try {
    const response = await fetch(`${apiHeygenConfig.serverUrl}/v1/streaming.stop`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-Api-Key': apiHeygenConfig.apiKey,
      },
      body: JSON.stringify({
        session_id: sessionInfo.session_id,
      }),
    });
    console.log(response);

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    if (peerConnection) {
      peerConnection.close();
      setPeerConnection(null);
    }

    setSessionInfo(null);
    if (mediaElement.current) {
      mediaElement.current.srcObject = null;
    }
    console.log('Session closed');
  } catch (error) {
    console.log('Error closing session: close');
    console.error('Close session error:', error);
  }
};

const checkAllAvatars = () => {
  const options = {
    method: 'GET',
    url: 'https://api.heygen.com/v1/streaming/avatar.list',
    headers: {
      accept: 'application/json',
      'x-api-key': apiHeygenConfig.apiKey,
    }
  };

  axios
    .request(options)
    .then(res => console.log(res.data))
    .catch(err => console.error(err));
};

export const AIAvatarDaoServices = {
  createNewSession,
  startMeetingSession,
  sendText,
  closeSession,
  checkAllAvatars
};