import {HiOutlineSpeakerWave, HiOutlineSpeakerXMark} from 'react-icons/hi2';
import { MuteButtonComponentType } from '../common/types';


type MuteHologramButtonType = MuteButtonComponentType & {
  isIdleVideo: boolean;
}

export const MuteHologramButtonComponent = ({isAvatarMuted, muteAvatar, isIdleVideo}: MuteHologramButtonType) => {

  return(
    <>
      {!isIdleVideo &&
        <div className={'hologram_volume_container'}>
          {
            isAvatarMuted ?
              <HiOutlineSpeakerXMark onClick={() => muteAvatar()}
                className={'holobox_volume_button holobox_muted_button'}/>
              :
              <HiOutlineSpeakerWave onClick={() => muteAvatar()} className={'holobox_volume_button'}/>
          }
        </div>
      }
    </>);

};