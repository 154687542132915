import { useTranslate } from '@tolgee/react';
import { Col, Row, Table } from 'antd';
import type { ColumnsType, TablePaginationConfig } from 'antd/es/table';
import { FilterValue, SorterResult } from 'antd/es/table/interface';
import { Link } from 'react-router-dom';
import styles from '../../../../assets/styles/table.module.scss';
import { ascDescSortDirections } from '../../../../common/constants';
import { UserRoleEnum } from '../../../../common/enums/userRoleEnum';
import { ColumnKeysEnum } from '../../../../common/enums/usersTableEnum';
import { useUserDataSource } from '../../../../common/hooks/useUserDataSource';
import { User } from '../../../../common/interfaces/user';
import { getFullName } from '../../../../common/utils/helpers';
import { useAuthConfigSelector } from '../../../auth/store/authSelectors';
import { Button } from '../../../ui/Button/Button';
import { FilterInput } from '../../../ui/FilterInput/FilterInput';
import TableFooter from '../../../ui/TableFooter/TableFooter';
import { ClinicServiceEnum } from '../../enums/clinicService';
import { UserInfo } from '../../services/AppointmentCreateDaoService';
import { useAppointmentCreateActionCreators } from '../../store/appointmentCreateSlice';

type AppointmentCreatePatientListTypes = {
  isSelect?: boolean;
  provider?: User | null;
  roles?: UserRoleEnum[];
  providerId?: number;
  empty?: boolean;
};

export const ClinicalServiceFiltersMap: Record<ClinicServiceEnum, string> = {
  [ClinicServiceEnum.CaseManager]: 'assignedCaseManager',
  [ClinicServiceEnum.Clinician]: 'assignedClinician',
  [ClinicServiceEnum.Psychologist]: 'assignedPsychologist',
  [ClinicServiceEnum.ContactInvestigator]: 'assignedContactInvestigator',
  [ClinicServiceEnum.Pharmacist]: 'assignedPharmacist',
  [ClinicServiceEnum.PeerNavigator]: 'assignedPeerNavigator'
};

export const AppointmentCreatePatientList = (props: AppointmentCreatePatientListTypes) => {
  const { t } = useTranslate();
  const { isSelect, provider, empty, ...initialParams } = props;

  const { user } = useAuthConfigSelector();
  const userRole = user?.role.id;
  const appointmentCreateActions = useAppointmentCreateActionCreators();
  const {
    dataSource,
    onFilterChange,
    setPageSize,
    totalRecords,
    currentPage,
    pageSize,
    setCurrentPage,
    filters,
    onSortingChange
  } = useUserDataSource({ initialParams, provider, empty });

  const clinicServiceProviderColums: ColumnsType<UserInfo> = [
    {
      title: t('common.clinicServiceTypes.ContactInvestigator.name'),
      key: ColumnKeysEnum.assignedContactInvestigator,
      sorter: true,
      sortDirections: ascDescSortDirections,
      children: [
        {
          title: (
            <FilterInput filterKey={ClinicalServiceFiltersMap['Contact Investigator']} onChange={onFilterChange} />
          ),
          dataIndex: ClinicalServiceFiltersMap['Contact Investigator'],
          key: ClinicalServiceFiltersMap['Contact Investigator'],
          render: (_, record) => {
            const id = record[ColumnKeysEnum.assignedContactInvestigator]?.id;
            return <Link to={`/providers/${id}`}>{getFullName(record.assignedContactInvestigator)}</Link>;
          }
        }
      ]
    },
    {
      title: t('common.clinicServiceTypes.Clinician.name'),
      key: ColumnKeysEnum.clinicServiceType,
      sorter: true,
      sortDirections: ascDescSortDirections,
      children: [
        {
          title: (
            <FilterInput
              filterKey={ClinicalServiceFiltersMap.Clinician}
              onChange={onFilterChange}
              defaultValue={`${filters['assignedClinician'] || ''}`}
            />
          ),
          dataIndex: ClinicalServiceFiltersMap.Clinician,
          key: ClinicalServiceFiltersMap.Clinician,
          render: (_, record) => {
            const id = record?.assignedClinician?.id;
            return <Link to={`/providers/${id}`}>{getFullName(record.assignedClinician)}</Link>;
          }
        }
      ]
    },
    {
      title: t('common.clinicServiceTypes.CaseManager.name'),
      key: ColumnKeysEnum.assignedCaseManager,
      sorter: true,
      sortDirections: ascDescSortDirections,
      children: [
        {
          title: (
            <FilterInput
              filterKey={ClinicalServiceFiltersMap['Case Manager']}
              onChange={onFilterChange}
              defaultValue={`${filters['assignedCaseManager'] || ''}`}
            />
          ),
          dataIndex: ClinicalServiceFiltersMap['Case Manager'],
          key: ClinicalServiceFiltersMap['Case Manager'],
          render: (_, record: UserInfo) => {
            const id = record[ColumnKeysEnum.assignedCaseManager]?.id;
            return <Link to={`/providers/${id}`}>{getFullName(record.assignedCaseManager)}</Link>;
          }
        }
      ]
    },
    {
      title: t('common.clinicServiceTypes.Pharmacist.name'),
      key: ColumnKeysEnum.lastVisitedPharmacist,
      sorter: true,
      sortDirections: ascDescSortDirections,
      children: [
        {
          title: <FilterInput filterKey="lastVisitedPharmacist" onChange={onFilterChange} />,
          dataIndex: 'lastVisitedPharmacist',
          key: 'lastVisitedPharmacist',
          render: (_, record: UserInfo) => {
            const id = record[ColumnKeysEnum.lastVisitedPharmacist]?.id;
            return <Link to={`/providers/${id}`}>{getFullName(record.lastVisitedPharmacist)}</Link>;
          }
        }
      ]
    },
    {
      title: t('common.clinicServiceTypes.Psychologist.name'),
      key: ColumnKeysEnum.assignedPsychologist,
      sorter: true,
      sortDirections: ascDescSortDirections,
      children: [
        {
          title: (
            <FilterInput
              filterKey={ClinicalServiceFiltersMap.Psychologist}
              onChange={onFilterChange}
              defaultValue={`${filters['assignedPsychologist'] || ''}`}
            />
          ),
          dataIndex: ClinicalServiceFiltersMap.Psychologist,
          key: ClinicalServiceFiltersMap.Psychologist,
          render: (_, record) => {
            const id = record[ColumnKeysEnum.assignedPsychologist]?.id;
            return <Link to={`/providers/${id}`}>{getFullName(record.assignedPsychologist)}</Link>;
          }
        }
      ]
    }
  ];

  const columns: ColumnsType<UserInfo> = [
    isSelect
      ? {
        title: '',
        dataIndex: '',
        key: '',
        render: (_, record) => (
          <Button
            className={`${styles.button} buttonOutlined`}
            onClick={() => {
              appointmentCreateActions.setPatient(record);
            }}
          >
            {t('features.AppointmentCreatePatientList.selectBtn.text')}
          </Button>
        )
      }
      : {},
    {
      title: t('common.patientData.id.label'),
      key: ColumnKeysEnum.uniqueCode,
      sorter: true,
      sortDirections: ascDescSortDirections,
      children: [
        {
          title: <FilterInput filterKey="uniqueCode" onChange={onFilterChange} />,
          dataIndex: 'uniqueCode',
          key: 'uniqueCode',
          render: (text, record) => <Link to={`/patients/${record.id}`}>{text}</Link>,
        }
      ]
    },
    {
      title: t('features.AppointmentCreatePatientList.firstNameColumn.title'),
      key: ColumnKeysEnum.firstName,
      sorter: true,
      sortDirections: ascDescSortDirections,
      children: [
        {
          title: <FilterInput filterKey="firstName" onChange={onFilterChange} />,
          dataIndex: 'firstName',
          key: 'firstName',
          render: (_, record) => <p>{record.firstName}</p>
        }
      ]
    },
    {
      title: t('features.AppointmentCreatePatientList.lastNameColumn.title'),
      key: ColumnKeysEnum.lastName,
      sorter: true,
      sortDirections: ascDescSortDirections,
      children: [
        {
          title: <FilterInput filterKey="lastName" onChange={onFilterChange} />,
          dataIndex: 'lastName',
          key: 'lastName',
          render: (_, record) => <p>{record.lastName}</p>
        }
      ]
    },
    {
      title: t('common.patientData.email.label'),
      key: ColumnKeysEnum.email,
      sorter: true,
      sortDirections: ascDescSortDirections,
      children: [
        {
          title: <FilterInput filterKey="email" onChange={onFilterChange} />,
          dataIndex: 'email',
          key: 'email',
          render: (_, record) => <p>{record.email}</p>
        }
      ]
    },
    {
      title: t('common.patientData.phone.label'),
      key: ColumnKeysEnum.phone,
      sorter: true,
      sortDirections: ascDescSortDirections,
      children: [
        {
          title: <FilterInput filterKey="phone" onChange={onFilterChange} />,
          key: 'phone',
          dataIndex: 'phone',
          render: (text) => <p>{text}</p>
        }
      ]
    },
    ...(userRole === UserRoleEnum.Admin ? clinicServiceProviderColums : [])
  ];

  const onTableStateChange = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<UserInfo> | SorterResult<UserInfo>[]
  ) => {
    if (!Array.isArray(sorter)) {
      const { columnKey, order } = sorter;
      onSortingChange(columnKey as ColumnKeysEnum, order);
    }
  };

  return (
    <Row className="container">
      <Col className="tableContainer">
        <Table
          columns={columns}
          dataSource={dataSource}
          locale={{ emptyText: t('common.noData.label') }}
          pagination={{
            pageSize: pageSize,
            current: currentPage,
            style: { display: 'none' }
          }}
          scroll={{ x: 'max-content' }}
          rowKey={(record) => record.id}
          onChange={onTableStateChange}
        />
      </Col>
      <TableFooter
        pageSize={pageSize}
        setPageSize={setPageSize}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        totalRecords={totalRecords}
      />
    </Row>
  );
};
