import {
  ClinicServiceDaoService,
  Provider,
  Service
} from '../../../features/clinicService/services/ClinicServiceDaoService';
import { ServiceComponent } from './ServiceComponent';
import { Dispatch, SetStateAction } from 'react';
import { useTranslate } from '@tolgee/react';
import { BackButton } from '../../../features/ui';
import { useNavigate } from 'react-router';

type ServicesComponentType = {
  services: Service[];
  setSelectedService: Dispatch<SetStateAction<Service | null>>;
  setUsers: Dispatch<SetStateAction<Provider[]>>;
}

export const ServicesComponent = ({services, setSelectedService, setUsers}:ServicesComponentType) => {
  const {t} = useTranslate();
  const navigate = useNavigate();
  const handleClick = async (service: Service, users: number[]) => {
    setSelectedService(service);
    const results = await Promise.all(users.map((id) => ClinicServiceDaoService.getProvider(id)));
    setUsers(results);
  };
  
  return(
    <>
      <div style={{
        display: 'flex',
        textAlign: 'center',
        justifyContent: 'space-between',
        alignContent: 'center',
        gap: 20,
        borderBottom: '1px solid rgba(0, 0, 0, 0.25)'
      }}>
        <BackButton label={t('common.backBtn.label')} onClick={() => navigate('/ai-assistant')} />
        <p>Select a Service</p>
        <div style={{ width: 80 }}></div>
      </div>
      <div style={{ marginTop: 20, marginLeft: 10, marginRight: 10, display: 'flex', flexDirection: 'column', gap: 20}}>
        {services.map((service) => {
          return <ServiceComponent service={service} handleClick={handleClick} key={service.id} />;
        })}
      </div>
    </>
  );

};