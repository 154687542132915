import { User } from '../../../common/interfaces/user';
import { TelehealthHttpService } from '../../../common/services/http/http.service';
import { LoginFormValues } from '../interfaces/authConfig';

interface LoginResponseType {
  token: string;
  tokenExpires: number; // timestamp
  user: User;
  changePassword?: boolean;
}

type AuthValidationResponse = {
  isValid: boolean;
};

type LoginResponse = {
  token: string;
  tokenExpires: number; // timestamp
  refreshToken: string;
  refreshTokenExpires: number;
  user: User;
};

const getUser = () => {
  return TelehealthHttpService.url('/auth/me').get<User>();
};

const login = (credentials: LoginFormValues) => {
  return TelehealthHttpService.url('/auth/admin/email/login').post<LoginResponseType>(credentials);
};

const logout = () => {
  return TelehealthHttpService.url('/auth/logout').post();
};

const changePassword = (credentials: { email: string; password: string; oldPassword: string }) => {
  return TelehealthHttpService.url('/auth/change/password').post<void>(credentials);
};

const validateUniqueCode = (code: {uniqueCode: string}) => {
  return TelehealthHttpService.url('/account-setup/unique-code/validate').post<AuthValidationResponse>(code);
};

const loginPatient = (code: {uniqueCode: string; firebaseToken: string | null}) => {
  return TelehealthHttpService.url('/auth/user/login').post<LoginResponseType>(code);
};

export const AuthDaoService = {
  login,
  logout,
  changePassword,
  getUser,
  validateUniqueCode,
  loginPatient
};
