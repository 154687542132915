import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import { oneMinute } from '../common/constants';


type useRecordingAudioType = {
  setOwnMessage: Dispatch<SetStateAction<string>>
  handleSend: () => void;
}


const useRecordingAudio = ({setOwnMessage, handleSend}: useRecordingAudioType) => {
  const [isRecording, setIsRecording] = useState(false);
  const recognitionRef = useRef<SpeechRecognition | null>(null);
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    if (!('webkitSpeechRecognition' in window || 'SpeechRecognition' in window)) {
      alert('Speech is not supported in your browser');
      return;
    }

    const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;
    const recognition = new SpeechRecognition();
    recognition.lang = 'en-US';
    recognition.interimResults = true;
    recognition.continuous = true;

    recognition.onresult = (event) => {
      let interimTranscript = '';
      for (let i = event.resultIndex; i < event.results.length; i++) {
        if (event.results[i].isFinal) {
          const newText = event.results[i][0].transcript + ' ';
          setOwnMessage((prevText) => prevText + newText);
        } else {
          interimTranscript += event.results[i][0].transcript;
          console.log(interimTranscript);
        }
      }
    };

    recognition.onerror = (event) => {
      console.error('Problem with listening:', event.error);
    };

    recognitionRef.current = recognition;
  }, []);

  const startRecording = () => {
    if (!recognitionRef.current) return;
    setOwnMessage('');
    setIsRecording(true);
    recognitionRef.current.start();

    timeoutRef.current = setTimeout(() => {
      stopRecording();
      handleSend();
    }, 2 * oneMinute);
  };

  const stopRecording = () => {
    if (!recognitionRef.current) return;
    setIsRecording(false);
    recognitionRef.current.stop();

    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
      timeoutRef.current = null;
    }

    handleSend();
  };
  
  const endRecording = () => {
    if (!recognitionRef.current) return;
    setIsRecording(false);
    recognitionRef.current.stop();

    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
      timeoutRef.current = null;
    }
    setOwnMessage('');
  };

  return{
    isRecording,
    stopRecording,
    startRecording,
    endRecording,
  };
};

export default useRecordingAudio;