import { TelehealthHttpService } from '../../../common/services/http/http.service';
import { AppointmentTypeEnum } from '../../../common/enums/appointmentTypeEnum';
import { Appointment } from '../../../features/appointment/interfaces/appointment';
import { Slot } from '../../../features/clinicService/services/ClinicServiceDaoService';


export type BookAppointmentPayload = {
  slot: Slot;
  type: AppointmentTypeEnum;
  providerId: number;
  clinicServiceId: number;
  comment: string;
};

export type ConfirmAppoinmentPayload = {
  id: string;
  userId: number;
  seriesId?: number;
};
export type Pagination = {
  page: number;
  limit: number;
};

export type GetAppointmentsResponse = {
  data: Appointment[];
  hasNextPage: boolean;
};

export const paginationQueryParams = (paginationState: Pagination): URLSearchParams => {
  const urlSearchParams = new URLSearchParams();
  Object.entries(paginationState).forEach(([name, value]: [string, number]) => {
    urlSearchParams.append(name, value.toString());
  });

  return urlSearchParams;
};

const getAppointments = (pagination: Pagination) => {
  return TelehealthHttpService.url(`/appointment/upcoming?${paginationQueryParams(pagination)}`).get<GetAppointmentsResponse>();
};

const postConfirmAppointment = async (appoinmentConfirmPayload: ConfirmAppoinmentPayload) => {
  return TelehealthHttpService.url('/appointment/confirm').post<void>(appoinmentConfirmPayload);
};

const postBookAppointment = async (bookAppointmentPayload: BookAppointmentPayload) => {
  const meetingInfo =  await TelehealthHttpService.url('/appointment/book').post<Record<string, Appointment>>(bookAppointmentPayload);
  return meetingInfo;
};

const getAppointmentById = async (appointmentId: string) => {
  return TelehealthHttpService.url(`/appointment/${appointmentId}/details`).get<Appointment>();
};


export const AppointmentsDaoService = {
  getAppointments,
  postConfirmAppointment,
  postBookAppointment,
  getAppointmentById
};