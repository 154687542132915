import { Button } from 'antd';
import { Service } from '../../../features/clinicService/services/ClinicServiceDaoService';


type ServiceComponentType = {
  service: Service;
  handleClick: (service: Service, users: number[]) => void;
}

export const ServiceComponent = ({service, handleClick}: ServiceComponentType) => {

  return (
    <div key={service.id} style={{ display: 'flex', flexDirection: 'column', border: '1px solid rgba(221, 221, 221, 1)', padding: 10 }}>
      <p style={{margin: 0, fontSize: 18, fontWeight: 700, marginBottom: 5}}>{service.title}</p>
      <p style={{margin: 0, marginBottom: 20}}>{service.briefDescription}</p>
      <Button type={'primary'} disabled={!service.users.length}
        onClick={() => handleClick(service, service.users)}>Select this service</Button>
    </div>
  );
};