import { endConversationType } from '../common/types';
import { LuUserRound } from 'react-icons/lu';
import { useNavigate } from 'react-router';


export const StartALiveCallButtonComponent = ({handleEnd, endRecording}: endConversationType) => {
  const navigate = useNavigate();
  
  const handleClick = () => {
    handleEnd('Press start a live call');
    endRecording();
    navigate('/live-call-services');
  };
  
  
  return (
    <button className={'holobox_button holobox_start_live_call_button'} onClick={() => handleClick()}>
      <LuUserRound className={'live_call_icon'}/>
      <p>Live call</p>
    </button>
  );
};