import { T, useTranslate } from '@tolgee/react';
import { useEffect } from 'react';
import { useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { UserRoleEnum } from '../../common/enums/userRoleEnum';
import { useWindowSizeType } from '../../common/hooks/useWindowSizeType';
import { AccessConfigEnum, AccessService } from '../../features/access/AccessService';
import { AppointmentContainerAdmin, AppointmentContainerProvider } from '../../features/appointment/components';
import { useAuthConfigSelector } from '../../features/auth/store/authSelectors';
import { useAuthActionCreators } from '../../features/auth/store/authSlice';
import { Availability } from '../../features/availability/components/AvailabilitySchedule/AvailabilitySchedule';
import { ProfileProvider } from '../../features/profile/components/ProfileProvider/ProfileProvider';
import { Button } from '../../features/ui';
import { ViewSwitch } from '../../features/ui/ViewSwitch/VIewSwitch';
import styles from './home.module.scss';

const ALL_APPOINTMENTS_TAB_KEY = 'all_appointments';
const ALL_APPOINTMENTS_TAB_LABEL = <T keyName="common.allAppointmentsTab.label" />;
const ALL_MY_APPOINTMENTS_TAB_LABEL = <T keyName="common.allMyAppointmentsTab.label" />;

export const AVAILABILITY_TAB_KEY = 'availability';
const MY_AVAILABILITY_TAB_LABEL = <T keyName="common.myAvailabilityTab.label" />;

const ViewConfig = [
  {
    key: ALL_APPOINTMENTS_TAB_KEY,
    label: ALL_APPOINTMENTS_TAB_LABEL
  },
  {
    key: AVAILABILITY_TAB_KEY,
    label: MY_AVAILABILITY_TAB_LABEL
  }
];

const ViewConfigMy = [
  {
    key: ALL_APPOINTMENTS_TAB_KEY,
    label: ALL_MY_APPOINTMENTS_TAB_LABEL
  },
  {
    key: AVAILABILITY_TAB_KEY,
    label: MY_AVAILABILITY_TAB_LABEL
  }
];

export const HomePage = () => {
  const { t } = useTranslate();
  const navigate = useNavigate();
  const authActions = useAuthActionCreators();
  const { isDesktop } = useWindowSizeType();
  const [searchParams, setSearchParams] = useSearchParams();
  const currentView = searchParams.get('activeViewKey') || '';

  useEffect(() => {
    if (!currentView) {
      setSearchParams({ activeViewKey: ViewConfig[0].key });
    }
  }, []);

  const onViewChange = (activeViewKey: string) => {
    setSearchParams({ activeViewKey });
  };

  const { user } = useAuthConfigSelector();
  const userRole = user?.role.id;

  return (
    <div className={styles.container}>
      {(userRole === UserRoleEnum.Provider || userRole === UserRoleEnum.PeerNavigator) && (
        <div className={styles.providerContainer}>
          {isDesktop && (
            <div className={styles.leftPanel}>
              {user && <ProfileProvider endAction={authActions.getUser} showEditIcon user={user} isMyProfile />}
            </div>
          )}
          <div className={styles.content}>
            <ViewSwitch items={ViewConfigMy} activeView={currentView} onChange={onViewChange} />
            {currentView === ViewConfig[0].key ? (
              <>
                {AccessService.isMatchAccessConfig(AccessConfigEnum.createAppointmentButton, userRole) && (
                  <div className={styles.topLineProvider}>
                    <Button
                      type="primary"
                      className={styles.aiAssistantButton}
                      onClick={() => navigate('/ai-assistant')}
                    >
                      {t('common.aiAssistantBtn.text')}
                    </Button>
                    <Button
                      type="primary"
                      className={styles.button}
                      onClick={() => navigate('/appointments/appointment-create')}
                    >
                      {t('common.newAppointmentBtn.text')}
                    </Button>
                  </div>
                )}
                <AppointmentContainerProvider role={userRole} />
              </>
            ) : (
              <Availability />
            )}
          </div>
        </div>
      )}
      {userRole === UserRoleEnum.Admin && (
        <div className={styles.adminContainer}>
          <div className={styles.content}>
            <ViewSwitch items={ViewConfig} activeView={currentView} onChange={onViewChange} />
            {currentView === ViewConfig[0].key ? (
              <>
                {AccessService.isMatchAccessConfig(AccessConfigEnum.createAppointmentButton, userRole) && (
                  <div className={styles.topLineProvider}>
                    <Button
                      type="primary"
                      className={styles.aiAssistantButton}
                      onClick={() => navigate('/ai-assistant')}
                    >
                      {t('common.aiAssistantBtn.text')}
                    </Button>
                    <Button
                      type="primary"
                      className={styles.button}
                      onClick={() => navigate('/appointments/appointment-create')}
                    >
                      {t('common.bookAppointmentBtn.text')}
                    </Button>
                  </div>
                )}
                <AppointmentContainerAdmin />
              </>
            ) : (
              <Availability />
            )}
          </div>
        </div>
      )}
    </div>
  );
};
