import { TelehealthHttpService } from '../../../common/services/http/http.service';
import { ClinicService } from '../interfaces/clinicService';
import { AppointmentTypeEnum } from '../../../common/enums/appointmentTypeEnum';

export interface Provider {
  id: number;
  firstName: string | null;
  lastName: string | null;
  bio: string | null;
  email: string;
  phone: string;
  company: string | null;
  profileImage: string | null;
  typeOfProvider: string | null;
  region: string | null;
  location: string | null;
  address: string | null;
  prefix?: string;
  lastLoginAt: string; // ISO
  createdAt: string; // ISO
  updatedAt: string | null; // ISO
  deletedAt: string | null; //ISO
  uniqueCode: string;
  clinicServiceType?: ServiceType;
  clinicServiceTitle?: string;
  __entity: 'User';
}

export type ServiceType =
  | 'Clinician'
  | 'Case Manager'
  | 'Psychologist'
  | 'Contact Investigator'
  | 'Pharmacist'
  | 'Peer Navigator';

export interface Service {
  id: number;
  type: ServiceType;
  title: string;
  briefDescription: string;
  fullDescription: string;
  users: number[];
  slotDuration: number; // minutes
  hasAvailabilityRecords: boolean;
}
export type Slot = {
  time: string; // ISO
  types: AppointmentTypeEnum[];
};

export type ProviderSlotsResponse = {
  startDate: string; // "2023-10-04",
  endDate: string; //"2023-10-08",
  slots: Record<string /* "2023-10-08" */, Slot[]>;
};

const getList = (): Promise<ClinicService[]> => {
  return TelehealthHttpService.url('/clinic-service').get<ClinicService[]>();
};

const getServices = () => {
  return TelehealthHttpService.url('/clinic-service/with-assigned-admin').get<Service[]>();
};

const getProvider = async (providerId: number) => {
  return TelehealthHttpService.url(`/provider/${providerId}`).get<Provider>();
};
const getProviderSlots = (providerId: number) => {
  const timezoneOffset = new Date().getTimezoneOffset();
  return TelehealthHttpService.url(`/provider/${providerId}/timeslots?&timezoneOffset=${timezoneOffset}`).get<ProviderSlotsResponse>();
};
export const ClinicServiceDaoService = {
  getList,
  getServices,
  getProvider,
  getProviderSlots
};
