import { AudioComponentProps } from '../common/types';
import { MuteHologramButtonComponent } from '../features/MuteHologramButtonComponent';
import { HiOutlineMicrophone } from 'react-icons/hi';
import { VscSend } from 'react-icons/vsc';
import { AiOutlineBorder } from 'react-icons/ai';
import { Dispatch, SetStateAction } from 'react';
import { oneSecond } from '../common/constants';


type audioHologramType = AudioComponentProps & {
  isAvatarMuted: boolean;
  muteAvatar: () => void;
  isIdleVideo: boolean;
  isSendingMessage: boolean;
  isEndWelcomeVideo?: boolean;
  setIsEndWelcomeVideo?: Dispatch<SetStateAction<boolean>>;
  isFirstRecord?: boolean;
  setIsFirstRecord?: Dispatch<SetStateAction<boolean>>;
  isRecording?: boolean;
  startRecording: () => void;
  stopRecording: () => void;
  handleEnd: (typeOfAction: string) => void;
  endRecording: () => void;
  setIsIdleImg: Dispatch<SetStateAction<boolean>>;
  ownMessage: string;
}
export const AudioHologramComponent = ({ isRecording, endRecording, startRecording, handleEnd, stopRecording, isAvatarMuted, muteAvatar, isIdleVideo, isSendingMessage, isEndWelcomeVideo, setIsEndWelcomeVideo, setIsFirstRecord, isFirstRecord, setIsIdleImg, ownMessage }: audioHologramType) => {

  const isStart = !isRecording && !isSendingMessage;
  const isAIWork = isSendingMessage && !isRecording;
  const isSendVideo = isRecording && !isSendingMessage;

  const handleStart = () => {
    if(isEndWelcomeVideo && setIsEndWelcomeVideo && isFirstRecord && setIsFirstRecord) {
      setIsEndWelcomeVideo(false);
      setIsFirstRecord(false);
      setIsIdleImg(false);
      setTimeout(() =>{
        startRecording();
      }, oneSecond * 7);
    }
    else {
      startRecording();
    }
  };

  const handleOverConversation = () =>{
    handleEnd('Press button stop AI');
    endRecording();
  };

  return(
    <>
      <MuteHologramButtonComponent isIdleVideo={isIdleVideo} isAvatarMuted={isAvatarMuted} muteAvatar={muteAvatar}/>
      {isStart && <button className={'holobox_button holobox_audio_button'} onClick={handleStart}>
        <HiOutlineMicrophone className={'live_call_icon'} />
        <p>Start</p>
      </button>}
      {isSendVideo &&
        <button className={'holobox_button holobox_audio_button'} onClick={stopRecording} disabled={ownMessage === ''}>
          <VscSend className={'live_call_icon'} />
          <p>Send</p>
        </button>}
      {isAIWork && <button className={'holobox_button holobox_audio_button'} onClick={handleOverConversation}>
        <AiOutlineBorder className={'live_call_icon'} />
        <p>Stop AI</p>
      </button>}
    </>
  );
};