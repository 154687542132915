
type TimerComponentType = {
  leftTime: number;
}

export const TimerComponent = ({leftTime}: TimerComponentType) => {
  const minutes = Math.floor(leftTime / 60);
  const seconds = leftTime % 60;

  return(<div className={'timer_container'}>
    <p className={'timer_text'}>{minutes}:{seconds < 10 ? `0${seconds}` : seconds}</p>
  </div>);
};