
type MessageComponentType = {
  className?: string;
  message: string;
}

export const MessageComponent = ({className, message}: MessageComponentType) => {

  return(
    <div className={`message ${className}`}>
      <p>{message}</p>
    </div>
  );
};